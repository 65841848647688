<script lang="ts">
  import Winbox from './Winbox.svelte';
  import InsertField from './InsertField.svelte';
  import { createEventDispatcher, onMount } from 'svelte';

  export let intro: Intro;
  export let x: number | string = 'center';
  export let y: number | string = 'center';
  export let title: string = 'Fields';
  export let mode: 'one' | 'many' = 'one';

  const dispatch = createEventDispatcher();
  function change(e: CustomEvent<{ beforeAfter: 'before' | 'after'; fields: Field[] }>) {
    const data = {
      beforeAfter: e.detail.beforeAfter,
      selectedFields: e.detail.fields
    }
    dispatch('change', data);
  }

  function select(e: CustomEvent<{ beforeAfter: 'before' | 'after'; fields: Field[] }>) {
    const data = {
      beforeAfter: e.detail.beforeAfter,
      selectedFields: e.detail.fields
    }
    dispatch('select', data);
  }

  function close() {
    dispatch('close', []);
  }
</script>

<Winbox component={InsertField} props={{ intro, okButton: true, mode }} events={{ change, select, close }} {x} {y} {title} />
