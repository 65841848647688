<script lang="ts">
  import WidgetElevation from './WidgetElevation.svelte';
  import WidgetFrame from './WidgetFrame.svelte';
  import WidgetFieldsDialog from './WidgetFieldsDialog.svelte';
  import InsertFieldDialog from './InsertFieldDialog.svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { checkIgnore } from '../helpers';

  export let win: Window = window;

  let displayInsertField: InsertFieldParams | undefined;
  let displayWidgetFields: WidgetFieldsParams | undefined;
  let hoverElement: HTMLElement;
  let editingElement: HTMLElement;
  let wysiwygMode: WysiwygMode = 'inspecting';

  let frameOffset = new DOMRect(0, 0);
  let frameBoundries: DOMRect = new DOMRect();
  let winboxPosX = 'center';
  let winboxPosY = 'center';

  const dispatch = createEventDispatcher();

  onMount(() => {
    win.addEventListener('pointermove', (event) => {
      const target = <HTMLElement>event.target;
      if(!checkIgnore(target)){
        hoverElement = target;
        frameBoundries = hoverElement.getBoundingClientRect();
      }
    });
  });

  function onClone() {
    // propagate callbacks for displaying extra modal dialogs
    dispatch('clone', {
      editingElement,
      dialogInsertField: async (params: InsertFieldParams) => {
        displayInsertField = params;
        // TODO promise should contain selected field from dialog
      },
      dialogInsertFields: async (params: InsertFieldParams) => {
        displayInsertField = { ...params, mode: 'many' };
        // TODO promise should contain selected field from dialog
      },
      hide
    });
  }

  function dialogWidgetFields(params: WidgetFieldsParams) {
    displayWidgetFields = params;
  }

  function onMouseOver(e: CustomEvent<Function>) {
    // propagate callback to alter behaviour of <WidgetFrame/>
    dispatch('hover', {
      hoverElement,
      inspect: e.detail,
      dialogWidgetFields
    });
  }

  function onModeChange(e: CustomEvent<{ mode: WysiwygMode }>) {
    if (wysiwygMode !== 'editing') {
      displayInsertField = undefined;
      displayWidgetFields = undefined;
      editingElement = hoverElement;
    }

    wysiwygMode = e.detail.mode;
    dispatch('modechange', {
      mode: e.detail.mode,
      editingElement,
      dialogWidgetFields,
      hide
    });

    if (wysiwygMode === 'editing') {
      dispatch('editing', {
        mode: e.detail.mode,
        editingElement,
        dialogWidgetFields,
        hide
      });
    }
  }

  function onShowSource() {
    dispatch('showsource', {
      editingElement
    });
  }

  function onDelete() {
    dispatch('delete', {
      editingElement
    });
    hide()
  }


  $: if (frameBoundries) {
    let top = frameOffset.top + frameBoundries.top;
    let left = frameOffset.left + frameBoundries.left;
    winboxPosX = left < (window.innerWidth - frameBoundries.width) / 2 ? 'right' : 'left';
    winboxPosY = top < (window.innerHeight - frameBoundries.height) / 2 ? 'bottom' : 'top';
  }

  function hide() {
    editingElement = null;
    wysiwygMode = 'inspecting';
  }

</script>

<svelte:window
  on:keydown={(event) => {
    if (event.key === 'Escape') {
      hide();
    }
  }}
/>

{#if hoverElement || editingElement}
  <WidgetFrame
    mode={wysiwygMode}
    element={editingElement && wysiwygMode === 'editing' ? editingElement : hoverElement}
    bind:offset={frameOffset}
    bind:boundries={frameBoundries}
    on:clone={onClone}
    on:hover={onMouseOver}
    on:modechange={onModeChange}
    on:showsource={onShowSource}
    on:delete={onDelete}
  />
  {#if editingElement && wysiwygMode === 'editing'}
    <WidgetElevation element={editingElement} on:click={() => (wysiwygMode = 'inspecting')} />
    {#if displayInsertField}
      <InsertFieldDialog
        x={winboxPosX}
        y={winboxPosY}
        title="Insert Field"
        on:close={() => {displayInsertField = undefined; if (!displayWidgetFields) wysiwygMode = 'inspecting'}}
        on:select={(e) => displayInsertField && displayInsertField.fieldSelected && displayInsertField.fieldSelected(e.detail)}
        {...displayInsertField}
      />
    {/if}
    {#if displayWidgetFields}
      <WidgetFieldsDialog
        x={winboxPosX}
        y={winboxPosY}
        title="Fields"
        on:close={() => {displayWidgetFields = undefined; if (!displayInsertField) wysiwygMode = 'inspecting'}}
        on:change={(e) => displayWidgetFields && displayWidgetFields.fieldsChanged && displayWidgetFields.fieldsChanged(e.detail)}
        {...displayWidgetFields}
      />
    {/if}
  {/if}
{/if}
