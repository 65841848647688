<script lang="ts">
  import Winbox from './Winbox.svelte';
  import WidgetFields from './WidgetFields.svelte';
  import { fields as _fields } from './fast/schema';
  import { createEventDispatcher } from 'svelte';
  import { messageIdToExpression, expressionToMessageId } from './WidgetFields/messageIdToExpression';

  export let fields: Field[] = _fields;
  export let x: number | string = 'center';
  export let y: number | string = 'center';
  export let title: string = 'Fields';
  export let messageExpressions = { messageIdToExpression, expressionToMessageId };

  const dispatch = createEventDispatcher();
  function change(e: CustomEvent<Field[]>) {
    dispatch('change', e);
  }
  function keypress(e: CustomEvent<Field[]>) {
    dispatch('keypress', e);
  }
  function close() {
    dispatch('close', [])
  }

</script>

<Winbox component={WidgetFields} props={{ fields, messageExpressions }} events={{ change, keypress, close }} {x} {y} {title} />
