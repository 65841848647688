<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { messageIdToExpression, expressionToMessageId } from './messageIdToExpression';

  export let fields: Field[];
  export let field: Field;
  export let expanded: boolean[];
  export let index: number;
  export let monaco = undefined;
  export let messageExpressions = { messageIdToExpression, expressionToMessageId };

  const dispatch = createEventDispatcher();

  function monacoEdit(target: HTMLDivElement, props: MonacoEditorProps) {
    let editor = monaco.editor.create(target, {
      value: props.value,
      language: props.language,
      theme: 'vs-dark',
      automaticLayout: true
    });
    editor.onDidChangeModelContent((e) => {
      props.onchange(editor.getValue());
    });
    return {
      update(props: MonacoEditorProps) {
        if (editor.getValue() !== props.value) {
          const pos = editor.getPosition();
          editor.setValue(props.value);
          editor.setPosition(pos);
          props.onchange(props.value);
        }
        monaco.editor.setModelLanguage(editor.getModel(), props.language);
      },
      destroy: () => {
        editor.dispose();
      }
    };
  }

</script>

{#if monaco}
  {#if expanded[index]}
    <div
      class="monaco-container"
      use:monacoEdit={{
        value: field.messageId
          ? messageExpressions.messageIdToExpression(field.messageId)
          : (field.value || field.default || '').toString(),
        language: '',
        onchange: (value) => {
          field.value = value;
          dispatch('change', fields);
        }
      }}
      style="height: 100%;min-height: 100px;"
    />
  {/if}
{:else}
  <fast-text-area
    appearance="outline"
    cols="1000"
    resize="vertical"
    value={field.value || field.default || ''}
    on:keypress={(event) => {
      if (event.target) {
        field.value = event.target.value;
        dispatch('keypress', fields);
      }
    }}
    on:change={(event) => {
      if (event.target) {
        field.value = event.target.value;
        dispatch('change', fields);
      }
    }}
  />
{/if}
