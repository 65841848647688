<script lang="ts">
  import type { SvelteComponent } from 'svelte';
  import Winbox from 'winbox/src/js/winbox.js';
  import 'winbox/src/css/winbox.css';

  export let component: typeof SvelteComponent = undefined;
  export let html: string = undefined;
  export let props: { [prop: string]: any } = {};
  export let events: { [eventName: string]: (event: any) => void } = {};
  export let title = 'Fields';
  export let x: number | string = 'center';
  export let y: number | string = 'center';
  export let width = 400;
  export let height = 500;
  export let classes = '';
  let target: HTMLElement;

  function newComponent(Component: typeof SvelteComponent, target: Element) {
    const instance = new Component({ target, props });
    const eventNames = Object.keys(events);
    eventNames.forEach((eventName) => {
      instance.$on(eventName, events[eventName]);
    });
  }

  function winbox(target: HTMLElement, { component, html }: { component: typeof SvelteComponent; html: string }) {
    const _x = x === 'left' ? 0 : x === 'right' ? window.innerWidth - width : 'center';
    const _y = y === 'top' ? 0 : y === 'bottom' ? window.innerHeight - height : 'center';
    let box = new Winbox({ title, x: _x, y: _y, width, height, root: target, background: 'var(--theme-color)', onclose: (e) => {
      const close = events['close']
      if (typeof close === "function") close(e)
    } });
    const winboxBody = target.querySelector(`div.wb-body`);
    if (winboxBody) {
      if (html) {
        winboxBody.innerHTML = html;
      } else if (component) {
        newComponent(component, winboxBody);
      }
    }
    return {
      // destroy: () => {
      //   if (box && box.close) {
      //     box.close();
      //   }
      // },
      update: ({ component, html }: { component: typeof SvelteComponent; html: string }) => {
        if (box && box.close) box.close();
        box = new Winbox({
          title,
          x: box.x,
          y: box.y,
          width: box.width,
          height: box.height,
          root: target,
          background: 'var(--theme-color)',
          onclose: box.close
        });
        const winboxBody = target.querySelector(`div.wb-body`);
        console.log(winboxBody, html);
        if (winboxBody) {
          if (html) {
            winboxBody.innerHTML = html;
          } else if (component) {
            newComponent(component, winboxBody);
          }
        }
      }
    };
  }

</script>

<div id="winbox-target" class={classes} bind:this={target} use:winbox={{ html, component }} />

<style>
  #winbox-target {
    z-index: 1000000 !important;
    position: absolute;
  }

</style>
