<script lang="ts" context="module">
  import { writable } from 'svelte/store';
  export const state = writable({});
  export const typesCollection = new Map<string, IntroType>();
  export const fieldsCollection = new Map<string, IntroField>();
  export const rootType = writable<IntroType>(null);
  export const expanded = writable<{ [x: string]: boolean }>({});
  export const selectedFields = writable<string[]>([]);

  function setTypes(es: Array<IntroType>) {
    for (const e of es) {
      e.fields = ((e as any).fields || []).map((f) => {
        let type = f.type;
        while (type.ofType) type = type.ofType;
        return <IntroField>{ ...f, type: type.name };
      });
      typesCollection.set(e.name, e);
    }
  }

  function getId(context: string[], field: IntroField) {
    return [...context, field.name].join('.');
  }
</script>

<script lang="ts">
  import { FASTDesignSystemProvider, FASTTreeView, FASTTreeItem } from '@microsoft/fast-components';
  import { createEventDispatcher } from 'svelte';
  [FASTDesignSystemProvider, FASTTreeView, FASTTreeItem];

  const dispatch = createEventDispatcher();

  export let intro: Intro = undefined;
  export let rootName = 'query_root';
  export let context: string[] = undefined;
  export let types: IntroType[] = undefined;
  export let type: IntroType = undefined;
  export let mode: 'one' | 'many' = 'one';
  export let field: IntroField | IntroField[] = undefined;

  $: if (intro) update();

  function update() {
    types = JSON.parse(JSON.stringify(intro.types));
    setTypes(types);
    rootType.set(typesCollection.get(rootName));
  }

  async function itemClicked(event: MouseEvent, id: string) {
    let element = <HTMLElement>event.target;
    while(element.tagName!=="FAST-TREE-ITEM") element = element.parentElement;
    if (event.ctrlKey) {
      const start = element;
      $expanded[id] = true;
      setTimeout(() => expand(start), 100);
      function expand(node: Element) {
        const list = Array.from(node.querySelectorAll('fast-tree-item'));
        console.log('PickField', list);
        for (const element of list) {
          setTimeout(() => {
            $expanded[element.id] = true;
          }, 100);
        }
      }
      return;
    }
    await new Promise(r => setTimeout(r, 0));
    if (mode === 'one') {
      if(id.split('.').length<2){
        element['selected'] = false;
      } else {
        if (element['selected']) {
          $selectedFields = [id];
        } else if ($selectedFields[0] === id) {
          $selectedFields = [];
        }
      }
    } else if (mode === 'many') {
      if (element['selected']) {
        $selectedFields = [...$selectedFields, id];
      } else if ($selectedFields[0] === id) {
        $selectedFields = $selectedFields.filter((fid) => fid !== id);
      }
    }
    if ($expanded[id] !== !!element['expanded']) {
      $expanded[id] = !!element['expanded'];
      dispatch('expand', $expanded[id]);
    }
    notify();
  }

  function notify() {
    let fields = $selectedFields.map((k) => fieldsCollection.get(k)).filter(Boolean);
    const fieldsWithType = fields.map((field) => {
      const type = typesCollection.get(field.type);
      if (type) Object.assign(field, { $$: type });
      return field;
    });
    if (mode === 'one') {
      field = fieldsWithType[0] || null;
    } else if (mode === 'many') {
      field = fieldsWithType;
    }
    dispatch('change', field);
  }

  $: if ($rootType && $rootType.fields.length === 1) {
    for (const f of $rootType.fields) {
      $expanded[f.name] = true;
    }
  }
</script>

{#if typeof context === 'undefined'}
  <fast-design-system-provider use-defaults>
    <svelte:self {mode} context={[]} type={$rootType} on:change />
  </fast-design-system-provider>
{:else if type}
  {#each type.fields || [] as field}
    {#await getId(context, field) then id}
      <fast-tree-item
        {id}
        expanded={!!$expanded[id]}
        selected={$selectedFields.indexOf(id) > -1}
        on:click|self={(e) => itemClicked(e, id)}
      >
        {#await typesCollection.get(field.type) then fieldType}
          {fieldsCollection.set(id, field) ? '' : ''}
          <span style="{context.length===0?'font-weight:bold;':'font-style: italic;'};"
            on:click|self={(e) => itemClicked(e, id)}
            >
            {field.name}
          </span>
          {#if $expanded[id]}
            <svelte:self {mode} context={[...context, field.name]} type={fieldType} on:change />
          {:else if fieldType && fieldType.fields && fieldType.fields.length}
            <fast-tree-item />
          {/if}
        {/await}
      </fast-tree-item>
    {/await}
  {/each}
{:else}
  <p>type prop not passed</p>
{/if}
