<script lang="ts">
  import { FASTAccordionItem } from '@microsoft/fast-components';
  import { createEventDispatcher } from 'svelte';
  import OpenBookIcon from './OpenBookIcon.svelte';
  import WidgetFieldExpression from './WidgetFieldExpression.svelte';
  import WidgetFieldTranslation from './WidgetFieldTranslation.svelte';
  import { messageIdToExpression, expressionToMessageId } from './messageIdToExpression';

  export let field: Field;
  export let fields: Field[];
  export let expanded: boolean[];
  export let index: number;
  export let choosingMessageId: Field;
  export let monaco = undefined;
  export let messageExpressions = { messageIdToExpression, expressionToMessageId };

  /* To make sure these components are included in final bundle after tree shaking */
  [FASTAccordionItem];

  let editing = true;
  let clientWidth = 0;
  const dispatch = createEventDispatcher();

  function getAccordionItem(node: HTMLElement) {
    while (node && node.tagName !== 'FAST-ACCORDION-ITEM' && node.tagName !== 'BODY') {
      node = node.parentElement;
    }
    if (node.tagName === 'FAST-ACCORDION-ITEM') {
      return node;
    }
  }

  function tabClicked(e: PointerEvent) {
    let elm = getAccordionItem(<HTMLElement>e.target);
    if (elm) {
      elm['expanded'] = true;
      setTimeout(() => (elm['expanded'] = true));
    }
  }

  function defaultTab(target: HTMLElement, { field }: { field: Field }) {
    const { messageId } = field;
    if (typeof messageId === 'string' && messageId) {
      const accord = getAccordionItem(target);
      setTimeout(() => accord && accord['expanded'] && target.click(), 800);
    }
  }

</script>

<fast-accordion-item {...{ slot: 'item' }} bind:clientWidth>
  {#if expanded[index]}
    {#if typeof field.messageId === 'string'}
      <fast-tabs on:click={tabClicked} on:pointerdown={tabClicked}>
        <fast-tab slot="tab" on:click={() => (editing = true)}>
          <span class="icon">f(x)</span>
          Expression
        </fast-tab>
        <fast-tab slot="tab" use:defaultTab={{ field }} on:click={() => (editing = false)}>
          <OpenBookIcon />
          Translated Message
        </fast-tab>
        <fast-tab-panel slot="tabpanel" />
        <fast-tab-panel slot="tabpanel">
          <WidgetFieldTranslation bind:choosingMessageId bind:field />
        </fast-tab-panel>
      </fast-tabs>
    {/if}
  {/if}
  <div class="expression-wrapper" style="max-width: {clientWidth - 8}px;" class:hidden={!editing}>
    <WidgetFieldExpression {messageExpressions} bind:monaco bind:field bind:fields {expanded} {index} on:change />
  </div>
  <div slot="heading">
    {field.name}
    {field.required ? '(required)' : ''}
  </div>
</fast-accordion-item>

<style>
  .icon:not(.material-icons) {
    font-family: monospace;
    font-size: 0.8rem;
  }
  .icon {
    margin-right: 0.5rem;
    font-size: 0.95rem;
  }
  .expression-wrapper {
    width: calc(100% - 0.5rem) !important;
    min-height: 40px;
  }
  .expression-wrapper.hidden {
    position: absolute;
    z-index: -200;
    opacity: 0;
    pointer-events: none;
  }

</style>
