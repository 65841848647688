<script lang="ts">
  export let field: Field;
  export let choosingMessageId: Field;

</script>

<div class="translation">
  <span>Message ID</span>
  <button on:click={() => (choosingMessageId = field)}>
    {field.messageId || 'Select Message ID'}
  </button>
</div>

<style>
  .translation {
    padding: 2rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .translation button {
    background: #f0f0f0;
    border: none;
    outline: none;
    cursor: pointer;
  }

</style>
