<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { checkIframe, checkIgnore, isBlue } from '../helpers';
  import WidgetTippy from './WidgetTippy.svelte';

  const dispatch = createEventDispatcher();
  export let color = '#4a87ee';
  export let fallbackColor = 'orange';
  export let element: HTMLElement;
  export let mode = 'inspecting';
  export let text = 'tippy';
  export let offset = new DOMRect();
  export let boundries: DOMRect = null;
  let offsetVars = '';
  let positionVars = '';
  let otherVars = '';

  let iframe: HTMLIFrameElement;
  let removeScrollEventListeners: Function = () => null;
  let frameShown = true;
  let isBgBlue = false;
  let isDisabled = false;
  let displayTippy = false;
  let tippyIcon = 'settings';

  $: if (element) update();

  function update() {
    if (!checkIgnore(element)) {
      dispatch('hover', inspectCallback);
      boundries = boundries || new DOMRect();
      boundries = element.getBoundingClientRect();
      offsetVars = `
      --offset-top: ${offset.top}px;
      --offset-left: ${offset.left}px;
      --offset-right: ${offset.right}px;
      --offset-bottom: ${offset.bottom}px;
      `;
      if (offset.bottom) {
        positionVars = `
        --position-left: ${boundries.left}px;
        --position-top: ${boundries.top}px;
        --position-right: ${Math.min(offset.right, boundries.right)}px;
        --position-bottom: ${Math.min(offset.bottom, boundries.bottom)}px;
        --position-width: ${Math.min(offset.right, boundries.right) - boundries.left}px;
        --position-height: ${Math.min(offset.bottom, boundries.bottom) - boundries.top}px;
        `;
      } else {
        positionVars = `
        --position-left: ${boundries.left}px;
        --position-top: ${boundries.top}px;
        --position-right: ${boundries.right}px;
        --position-bottom: ${boundries.bottom}px;
        --position-width: ${boundries.width}px;
        --position-height: ${boundries.height}px;
        `;
      }

      otherVars = `
      --frame-width: 4px;
      --frame-half-width: calc(var(--frame-width) / 2);
      --frame-color: ${color};
      --frame-fallback-color: ${fallbackColor};
      `;
    }
  }

  function inspectCallback({ display, tooltipText, disabled, icon = 'settings' }) {
    if (!checkIgnore(element)) {
      tippyIcon = icon;
      text = tooltipText;
      isDisabled = !!disabled;
      displayTippy = !!display;
      if (!display) return;
      isBgBlue = isBlue(element);
      frameShown = false;
      boundries = element.getBoundingClientRect();
      iframe = checkIframe(element);
      if (iframe) {
        offset = iframe.getBoundingClientRect();
      } else {
        offset = new DOMRect();
      }
      const win = iframe ? iframe.contentWindow : window;
      win.removeEventListener('scroll', scrolled, true);
      win.addEventListener('scroll', scrolled, true);
      setTimeout(() => (frameShown = true), 0);
    }
  }

  function scrolled() {
    frameShown = displayTippy = false;
  }

</script>

<!-- Update on resize -->
<svelte:window on:resize={update} on:scroll={scrolled} />

{#if frameShown}
  <div
    class="frame-wrapper iteria-ignore"
    class:fallback-color={isBgBlue}
    style="{offsetVars}{positionVars}{otherVars}"
  >
    <div class="frame frame-top" />
    <div class="frame frame-right" />
    <div class="frame frame-bottom" />
    <div class="frame frame-left" />
  </div>
{/if}

{#if displayTippy && element}
  <WidgetTippy
    icon={tippyIcon}
    {offset}
    {boundries}
    {mode}
    {text}
    disabled={isDisabled}
    contentWindow={iframe ? iframe.contentWindow : window}
    css="{offsetVars}{positionVars}{otherVars}"
    on:modechange={() =>
      dispatch('modechange', { mode: mode === 'inspecting' ? 'editing' : 'inspecting', target: element })}
    on:clone
    on:delete
    on:showsource
  />
{/if}

<style>
  .frame-wrapper {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  }
  .frame {
    z-index: 10000;
    position: fixed;
    width: 20px;
    height: 20px;
    background: var(--frame-color);
    animation: fadein 300ms;
  }
  .frame-wrapper.fallback-color .frame {
    background: var(--frame-fallback-color);
  }
  .frame-top {
    top: calc(var(--offset-top) + var(--position-top) - var(--frame-half-width));
  }
  .frame-bottom {
    top: calc(var(--offset-top) + var(--position-bottom) - var(--frame-half-width));
  }
  .frame-left {
    left: calc(var(--offset-left) + var(--position-left) - var(--frame-half-width));
  }
  .frame-right {
    left: calc(var(--offset-left) + var(--position-right) - var(--frame-half-width));
  }
  .frame-top,
  .frame-bottom {
    left: calc(var(--offset-left) + var(--position-left) - var(--frame-half-width));
    width: calc(var(--position-width) + var(--frame-width));
    height: var(--frame-width);
  }
  .frame-left,
  .frame-right {
    top: calc(var(--offset-top) + var(--position-top));
    width: var(--frame-width);
    height: calc(var(--position-height) + var(--frame-width) - var(--frame-half-width));
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    top {
      opacity: 1;
    }
  }

</style>
