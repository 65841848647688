import Wysiwyg from './Wysiwyg.svelte';
export default class WysiwygHTMLElement extends HTMLElement {
    constructor() {
        super();
        this.contentWindow = window;
        this.component = undefined;
    }
    set win(win) {
        this.contentWindow = win;
    }
    get win() {
        return this.contentWindow;
    }
    connectedCallback() {
        this.style.width = "100%";
        this.style.height = "100%";
        this.component = new Wysiwyg({
            target: this,
            props: {
                win: this.contentWindow
            }
        });
        this.component.$on('hover', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('hover', { detail: data }));
        });
        this.component.$on('showsource', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('showsource', { detail: data }));
        });
        this.component.$on('clone', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('clone', { detail: data }));
        });
        this.component.$on('delete', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('delete', { detail: data }));
        });
        this.component.$on('modechange', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('modechange', { detail: data }));
        });
        this.component.$on('editing', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('editing', { detail: data }));
        });
    }
    disconnectedCallback() {
        this.component.$destroy();
    }
}
try {
    customElements.define('iteria-wysiwyg', WysiwygHTMLElement);
}
catch (e) {
    console.error('define iteria-wysiwyg', e);
    // noop
}
